<template id="fast-channel-view">
  <form
    ref="form"
    @submit.prevent="handleOk"
  >
    <b-row>
      <b-col>
        <h5>
          {{ $t('InfofastChannels') }}
        </h5>
      </b-col>
      <b-col style="align-self: center; text-align: end;">
        <b-button
          variant="success"
          @click="handleOk"
        >
          {{ $t('dataGeneric.save') }}
        </b-button>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          :label="$t('firstName') + ' *'"
          label-for="name-input"
          :invalid-feedback="$t('required')"
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="name"
            :placeholder="$t('firstName')"
            :state="nameState"
            maxlength="250"
            required
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          :label="$t('almacenamiento.buckts3') + ' *'"
          label-for="bucket-input"
          :invalid-feedback="$t('required')"
          :state="bucketState"
        >
          <b-form-input
            id="name-input"
            v-model="bucket"
            :placeholder="$t('almacenamiento.buckts3')"
            :state="bucketState"
            maxlength="250"
            required
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          :label="$t('almacenamiento.path') + ' *'"
          label-for="path-input"
          :invalid-feedback="$t('required')"
          :state="pathState"
        >
          <b-form-input
            id="path-input"
            v-model="path"
            :placeholder="$t('almacenamiento.path')"
            :state="pathState"
            maxlength="250"
            required
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          :label="$t('audioPath')"
          label-for="audioPath"
        >
          <b-form-input
            id="audioPath"
            v-model="audioPath"
            :placeholder="$t('audioPath')"
            maxlength="150"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          :label="$t('subtitlesPath')"
          label-for="subtitlesPath"
        >
          <b-form-input
            id="subtitlesPath"
            v-model="subtitlesPath"
            :placeholder="$t('subtitlesPath')"
            maxlength="150"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          :label="$t('accessKeyId') + ' *'"
          label-for="accessKeyId"
          :invalid-feedback="$t('required')"
          :state="accessKeyIdState"
        >
          <b-form-input
            id="accessKeyId"
            v-model="accessKeyId"
            :placeholder="$t('accessKeyId')"
            :state="accessKeyIdState"
            maxlength="250"
            required
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group
          :label="$t('secretAccessKey') + ' *'"
          label-for="secretAccessKey"
          :invalid-feedback="$t('required')"
          :state="secretAccessKeyState"
        >
          <b-form-input
            id="secretAccessKey"
            v-model="secretAccessKey"
            :placeholder="$t('secretAccessKey')"
            :state="secretAccessKeyState"
            maxlength="250"
            required
          />
        </b-form-group>
      </b-col>
    </b-row>
  </form>
</template>
<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { isEmpty, messageError, showToast } from '@/store/functions'
import { getUserData } from '@/auth/utils'

const fileError = require('@/assets/images/backend/file-error.png')

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    BFormInput,
    BButton,
  },

  directives: {
    Ripple,
  },
  props: {
    id: { default: null },
  },
  data() {
    return {
      badges: null,
      show: false,
      audioPath: '',
      subtitlesPath: '',
      name: '',
      path: '',
      bucket: '',
      secretAccessKey: '',
      accessKeyId: '',
      userData: getUserData(),
      nameState: null,
      pathState: null,
      bucketState: null,
      secretAccessKeyState: null,
      accessKeyIdState: null,
    }
  },
  mounted() {
    let defaultLang = 'es'
    try {
      defaultLang = this.userData.profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) {
    }
    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    if (this.id) this.getFastChannel()
  },
  methods: {
    getFastChannel() {
      const { headers } = this
      axios
        .post('', {
          query: `
          {
            allFastChannelChannel(id:"${this.id}")
            {
            edges
            {
                node
                {
                  id
                  name
                  path        
                  audioPath        
                  subtitlesPath        
                  secretAccessKey
                  accessKeyId
                  bucket
                }
              }
            }
          }
          `,
        }, { headers })
        .then(res => {
          messageError(res, this)
          const {
            name, path, audioPath, subtitlesPath, bucket, secretAccessKey, accessKeyId,
          } = res.data.data.allFastChannelChannel.edges[0].node
          this.name = name
          this.path = path
          this.audioPath = audioPath
          this.subtitlesPath = subtitlesPath
          this.bucket = bucket
          this.secretAccessKey = secretAccessKey
          this.accessKeyId = accessKeyId

          this.show = false
        })
        .catch(err => {
          console.log(err)
          this.show = false
        })
    },
    errorImg(e) {
      e.target.src = fileError
    },
    loadImage(item) {
      const { data, type } = item

      switch (type) {
        case 'imageFile':
          this.imageFile = data
          break
        default:
          break
      }
    },
    chooseInput(dato) {
      document.getElementById(dato).click()
    },
    handleOk() {
      if (this.id !== null) { this.editFastChannel() } else this.createFastChannel()
    },
    createFastChannel() {
      if (!this.checkFormValidity()) {
        return
      }
      const { headers } = this
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `
            mutation($name: String!, $path: String!,$audioPath: String,$subtitlesPath: String,$bucket: String!,$secretAccessKey: String!,$accessKeyId: String!){
              createFastChannelChannel(input:{ 
                  name: $name,
                  path: $path,
                  audioPath: $audioPath,
                  subtitlesPath: $subtitlesPath,
                  bucket: $bucket,
                  secretAccessKey: $secretAccessKey,
                  accessKeyId: $accessKeyId,
                  client:"${this.userData.profile.client.id}"}){
                fastChannelChannel {
                  id
                }
              }
            }
          `
      const variables = {
        name: this.name,
        path: this.path,
        audioPath: this.audioPath,
        subtitlesPath: this.subtitlesPath,
        bucket: this.bucket,
        secretAccessKey: this.secretAccessKey,
        accessKeyId: this.accessKeyId,
      }
      data.append('query', query)
      data.append('variables', JSON.stringify(variables))

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)
          showToast(this.$t('success'), 1, this)
          this.$emit('refresh')
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
          this.show = false
        })

      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing')
      })
    },
    deleteImage(type) {
      this.$swal({
        title: this.$t('removData'),
        text: this.$t('sure'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.isConfirmed) {
            this.deleteFile.push(type)
            switch (type) {
              case 'image':
                this.imageBol = false
                this.imageFile = null

                break

              default:
                break
            }
          }
        })
        .catch(() => { })
    },
    editFastChannel() {
      const { headers } = this

      if (!this.checkFormValidity()) {
        return
      }
      const data = new FormData()
      const config = {
        timeout: 1000 * 60 * 30,
      }
      const query = `mutation($name: String!, $path: String!, $audioPath: String,$client:ID! $subtitlesPath: String, $bucket: String!, $secretAccessKey: String!, $accessKeyId: String!)
      {
      updateFastChannelChannel(id:"${this.id}",input:{
        client:$client,
        name: $name,
        path: $path,
        audioPath: $audioPath,
        subtitlesPath: $subtitlesPath,
        bucket: $bucket,
        secretAccessKey: $secretAccessKey,
        accessKeyId: $accessKeyId,
      }){
                fastChannelChannel {
                   id
                 }
                }
            }`
      const variables = {
        name: this.name,
        path: this.path,
        client: this.userData.profile.client.id,
        audioPath: this.audioPath,
        subtitlesPath: this.subtitlesPath,
        bucket: this.bucket,
        secretAccessKey: this.secretAccessKey,
        accessKeyId: this.accessKeyId,
      }

      data.append('query', query)
      data.append('variables', JSON.stringify(variables))

      axios
        .post('', data, { headers }, config)
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.$emit('refresh')
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.show = false
        })
    },

    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()

      if (isEmpty(this.name)) { this.nameState = valid }
      if (isEmpty(this.bucket)) { this.bucketState = valid }
      if (isEmpty(this.secretAccessKey)) { this.secretAccessKeyState = valid }
      if (isEmpty(this.accessKeyId)) { this.accessKeyIdState = valid }
      if (isEmpty(this.path)) { this.pathState = valid }

      return valid
    },

  },
}
</script>

<style lang="scss" scoped>
#fast-channel-view .edit:hover {
  color: rgb(0, 83, 7);
  cursor: pointer;
}

#fast-channel-view .trash:hover {
  color: rgb(109, 0, 0);
  cursor: pointer;
}

#fast-channel-view .card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

#fast-channel-view .card-body h4 {
  font-size: 1.286rem !important;
}

#fast-channel-view .col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

#fast-channel-view .fly-image-input {
  max-width: 100%;
}

#fast-channel-view .base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#fast-channel-view .base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

#fast-channel-view .div {
  display: inline-block;
}

#fast-channel-view .inputColor {
  visibility: hidden;
}

#fast-channel-view .placeholder_photo {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

#fast-channel-view .placeholder_photo:hover {
  background: #e0e0e0;
}

#fast-channel-view .file-input {
  display: none;
}

#fast-channel-view .cBox {
  align-items: center;
}

#fast-channel-view .custom-control-label {
  font-size: 15px;
}

#fast-channel-view .scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

#fast-channel-view .list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

#fast-channel-view .contain {
  object-fit: contain;
  cursor: pointer;
}
</style>

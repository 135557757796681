<template>
  <section id="fastChannel">
    <b-row>
      <b-col>
        <h1>{{ $t('fastChannels') }}</h1>
        <h5 class="text-primary">
          {{ $t('InfofastChannels') }}
        </h5>
      </b-col>
    </b-row>
    <b-overlay
      :show="show"
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="mt-2 justify-content-end">
        <div class="d-flex  align-items-center">
          <b-col>
            <b-button
              v-if="isSuperuser"
              variant="primary"
              class="d-flex"
              @click="actionsCRUD('create')"
            >
              <feather-icon
                icon="FilePlusIcon"
                class="mr-50"
              />
              <span class="text-nowrap">{{ $t("addFchannel") }}</span>
            </b-button>
          </b-col>
        </div>
      </b-row>
      <!-- NÚMERO DE ELEMENTOS -->
      <b-row>
        <b-col class="d-flex justify-content-end mt-2">
          <span>
            <strong>{{ totalCount }}</strong> {{ $t("contents.elements") }}
          </span>
        </b-col>
      </b-row>
      <b-row class="match-height mt-2">
        <b-col
          v-for="folder in fastChannels"
          :id="folder.node.id"
          :key="folder.node.id"
          style="border-radius: 7px;"
          md="11"
          class="m-2 embed-responsive "
        >
          <b-card
            header-classes="bg-transparent"
            footer-classes="bg-transparent"
            body-classes="px-lg-7"
            class=" text-center mb-2"
            no-body
          >
            <b-card-body>
              <div class="actions">
                <div class="action">
                  <b-dropdown
                    v-if="isSuperuser"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    right
                  >
                    <template #button-content>
                      <feather-icon
                        icon="MoreHorizontalIcon"
                        size="20"
                      />
                    </template>
                    <b-dropdown-item
                      @click.stop.prevent="actionsCRUD(
                        'update',
                        folder.node
                      )"
                    >
                      <feather-icon
                        icon="Edit2Icon"
                        size="18"
                      />
                      {{ $t('dataGeneric.edit') }}
                    </b-dropdown-item>
                    <b-dropdown-item @click.stop.prevent="actionsCRUD('delete', folder.node)">
                      <feather-icon
                        icon="DeleteIcon"
                        size="18"
                      />
                      {{ $t('dataGeneric.delete') }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div
                id="acortarPadre"
                class="m-2 mt-5 body-card"
              >
                <div
                  class="wrap align-content-center"
                  style="height: 140px;"
                >
                  <h2 class="acortarParrafo">
                    {{ folder.node.name }}
                  </h2>
                </div>
                <div class="button">
                  <b-button
                    variant="outline-primary"
                    class="mr-2"
                    @click="exportElement(folder.node.id)"
                  >
                    <feather-icon
                      icon="UploadCloudIcon"
                      class="mr-50"
                    />
                    {{ $t('createUpload') }}
                  </b-button>
                  <b-button
                    variant="outline-primary"
                    class="mr-2"
                    @click="channelHistory(folder.node.id)"
                  >
                    <feather-icon
                      icon="EyeIcon"
                      class="mr-50"
                    />
                    {{ $t('history') }}
                  </b-button>
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
    <!-- edit -->
    <b-modal
      id="fast-channel-view"
      ref="view-fc"
      size="lg"
      hide-footer
      title="Fast channel"
      @hidden="$refs['view-fc'].hide()"
    >
      <div class="d-block">
        <fast-channel-view
          :id="id"
          @refresh="getData()"
        />
      </div>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['view-fc'].hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>

    <!-- upload -->
    <b-modal
      id="export-element"
      ref="export-element"
      size="xl"
      hide-footer
      :title="$t('createUpload')"
      @hidden="$refs['export-element'].hide()"
    >
      <b-tabs
        lazy
        pills
      >
        <b-tab>
          <template #title>
            <div class="">
              {{ $t('Contents') }}
            </div>
          </template>
          <content-selector
            :multi="true"
            :limit-content="100"
            @data="data => previewFastChannel(data, 'content')"
          />
        </b-tab>
        <b-tab>
          <template #title>
            <div class="">
              {{ $t('Categories') }}
            </div>
          </template>
          <category-selector
            :type="true"
            :multi="true"
            :limit-content="100"
            @data="data => previewFastChannel(data, 'category')"
          />
        </b-tab>
      </b-tabs>
      <b-button
        class="mt-3"
        variant="outline-danger"
        block
        @click="$refs['export-element'].hide()"
      >
        {{ $t("dataGeneric.close") }}
      </b-button>
    </b-modal>
    <!-- preview -->
    <b-modal
      id="preview-upload"
      ref="preview-upload"
      size="xl"
      scrollable
      hide-footer
      :title="$t('previewUpload')"
      @hidden="$refs['preview-upload'].hide()"
    >
      <b-row class="mb-2 mt-2">
        <b-col>
          <h4>
            {{ $t("exportContent") }}
          </h4>
        </b-col>
        <b-col class="d-flex justify-content-end">
          <b-button
            variant="danger"
            class="mr-2"
            @click="$refs['preview-upload'].hide()"
          >
            {{ $t("dataGeneric.cancel") }}
          </b-button>
          <b-button
            variant="success"
            @click="exportFastChannel"
          >
            {{ $t("media.upload") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-table
            ref="previewContent"
            :items="totalExports"
            :fields="[
              { key: 'image', label: $t('Image') },
              { key: 'id', label: $t('id') },
              { key: 'name', label: $t('name') },
            ]"
            responsive="sm"
            show-empty
            :empty-text="$t('emptyText')"
            primary-key="id"
          >
            <template #cell(image)="data">
              <b-img
                id="avatar"
                rounded="lg"
                :src="buildImageUrl(data.item)"
              />
            </template>

            <template #cell(name)="data">
              <div class="text-nowrap">
                <span class="align-text-top">{{ data.item.node.name }}</span>
              </div>
            </template>
            <template #cell(id)="data">
              <div class="text-nowrap">
                <b-link
                  style="color:white; text-decoration: underline;"
                  class="cursor-pointer"
                  :href="'/contents/contents/edit/' + data.item.node.id"
                  target="_blank"
                >
                  <span class="align-text-top">{{ data.item.node.id }}</span>
                </b-link>
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>
  </section>
</template>
<script>

import {
  BButton,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BLink,
  BTable,
  BTabs,
  BTab,
  BImg,
  BModal,
  BOverlay,
  BDropdown,
  BDropdownItem,
  VBTooltip,
} from 'bootstrap-vue'
import {
  showToast, messageError,
  isEmpty,
  utf8ToB64,
} from '@/store/functions'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { getUserData } from '@/auth/utils'
import { required } from '@validations'
import FastChannelView from './FastChannelView.vue'
import ContentSelector from '../common/ContentSelector.vue'
import CategorySelector from '../common/CategorySelector.vue'

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BButton,
    BModal,
    BTable,
    BLink,
    BRow,
    BCol,
    BCard,
    BDropdown,
    BImg,
    BDropdownItem,
    BCardBody,
    BOverlay,
    ContentSelector,
    CategorySelector,
    FastChannelView,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isEmpty,
      required,
      totalExports: [],
      show: false,
      id: null,
      name: '',
      description: '',
      headers: {},
      contents: [],
      typeExportation: '',
      idsExportation: '',
      isSuperuser: false,
      totalCount: 0,
      fastChannels: [],
      hasMore: true,
    }
  },

  mounted() {
    window.addEventListener('scroll', this.handleScroll)

    let defaultLang = 'es'
    try {
      defaultLang = getUserData().profile.client.defaultLanguage
      // eslint-disable-next-line no-empty
    } catch (error) { }

    this.headers = {
      'Accept-Language': defaultLang,
      'Content-Language': defaultLang,
    }
    this.isSuperuser = getUserData().isSuperuser
    this.getData()
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      const element = this.$refs.infiniteScroll
      const rect = element.getBoundingClientRect()
      if (rect.top <= window.innerHeight && this.hasMore && !this.isLoading) {
        this.channelHistory(this.fastChannelId)
      }
    },

    formatDate(value) {
      const date = new Date(value)
      const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }

      return value != null
        ? new Intl.DateTimeFormat('default', options).format(date)
        : value
    },

    getData() {
      const { headers } = this
      const userData = getUserData()
      this.show = true
      this.$refs['view-fc'].hide()

      const query = `{
            allFastChannelChannel(              
              clientId:"${userData.profile.client.id}",
              ){
              totalCount
              edges {
                node {
                  id
                  name
                }
              }
            }
          }`

      axios
        .post('', {
          query,
        }, { headers })
        .then(result => {
          messageError(result, this)

          this.totalCount = result.data.data.allFastChannelChannel.totalCount
          this.fastChannels = result.data.data.allFastChannelChannel.edges

          this.show = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    exportElement(id) {
      this.fastChannelId = id
      this.$refs['export-element'].show()
    },
    channelHistory(id) {
      this.$router.push({ name: 'channel-history', params: { id } })
    },

    previewFastChannel(data, type) {
      const { headers } = this
      this.idsExportation = data
      this.typeExportation = type
      let query = '{'
      if (this.typeExportation === 'content') {
        this.idsExportation.forEach(content => {
          query += `cont_${content}:allContents(id:"${content}") {
              edges {
                node {
                  id
                  name
                  imageUrl
                }
              }
            } `
        })
      } else {
        this.idsExportation.forEach(category => {
          const encondedCode = utf8ToB64(`id:${category}`)
          query += `cat_${category}:relationCategoryContents(categoryId:"${category}") {
              edges {
                node {
                  id
                  name
                  imageUrl
                   contentContentOrder(category:"${encondedCode}") {
                    totalCount
                    edges {
                      node {
                        id
                        order
                        category {
                          id
                          name
                        }
                      }
                    }
                  }
                }
              }
            } `
        })
      }
      query += '}'
      axios
        .post('', { query }, { headers })
        .then(res => {
          messageError(res, this)
          const resultado = res.data.data
          this.totalExports = []

          if (this.typeExportation === 'content') {
            this.idsExportation.forEach(ids => {
              const element = resultado[`cont_${ids}`]
              this.totalExports.push(element.edges[0])
            })
          } else {
            this.idsExportation.forEach(ids => {
              const element = resultado[`cat_${ids}`]
              const contentCategories = element.edges
              contentCategories.forEach(content => {
                this.totalExports.push(content)
              })
            })
          }
          this.$refs['preview-upload'].show()
        })
        .catch(() => {
          this.show = false
        })
    },
    buildImageUrl(folder) {
      return folder.node.imageUrl == null || folder.node.imageUrl.length === 0
        ? noCover
        : folder.node.imageUrl
    },
    exportFastChannel() {
      let categoryIds = []
      let contentIds = []
      if (this.typeExportation === 'content') {
        contentIds = this.idsExportation.map(item => item)
      } else {
        categoryIds = this.idsExportation.map(item => item)
      }
      const query = `
        mutation ($categoryIds: [ID]!, $contentIds: [ID]!, $fastChannelId: ID!) {
          exportContentsCategoriesToFastChannel(categoryIds: $categoryIds, contentIds: $contentIds, fastChannelId: $fastChannelId) {
            batchId
          }
        }
        `
      const variables = {
        categoryIds,
        contentIds,
        fastChannelId: this.fastChannelId,
      }
      axios
        .post('', { query, variables })
        .then(res => {
          messageError(res, this)

          showToast(this.$t('code.updateData'), 1, this)
          this.$refs['export-element'].hide()
          this.$refs['preview-upload'].hide()
        })
        .catch(() => {
          showToast(this.$t('code.updateDataError'), 2, this)
          this.show = false
        })
    },
    actionsCRUD(value, data = []) {
      const { id } = data
      switch (value) {
        case 'update':
          this.id = id
          this.$refs['view-fc'].show()

          break
        case 'create':
          this.id = null
          this.$refs['view-fc'].show()
          break
        case 'delete':
          this.show = true
          this.$swal({
            title: this.$t('code.title'),
            text: this.$t('remFchannel'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: this.$t('code.confirm'),
            cancelButtonText: this.$t('dataGeneric.cancel'),
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.value) {
              axios
                .post('', {
                  query: `
                  mutation{
                    deleteFastChannelChannel(id:"${id}"){
                          found
                          deletedId
                      }
                  }
                  `,
                })
                .then(res => {
                  messageError(res, this)
                  showToast(this.$t('code.updateData'), 1, this)
                  this.getData()
                })
                .catch(() => {
                  showToast(this.$t('code.updateDataError'), 2, this)
                  this.show = false
                })
            } else {
              this.show = false
            }
          }).catch(err => {
            console.log(err)
          })
          break

        default:
          break
      }
    },

  },
}
</script>
<style lang="scss" scoped>
.card-body {
  padding: 1rem;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 1px;
}

.card-body h4 {
  font-size: 1.286rem !important;
}

.col-form-label {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.fly-image-input {
  max-width: 100%;
}

.base-color-input {
  display: block;
  border-radius: 15px;
  border: solid 1px;
  border-color: white;
  cursor: pointer;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.base-card-input {
  display: block;
  cursor: pointer;
  background-size: cover;
  background-position: center center;
}

.div {
  display: inline-block;
}

.inputColor {
  visibility: hidden;
}

.placeholder_photo {
  background: #f0f0f0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #333;
  font-size: 18px;
  font-family: Helvetica;
}

.placeholder_photo:hover {
  background: #e0e0e0;
}

.file-input {
  display: none;
}

.cBox {
  align-items: center;
}

.custom-control-label {
  font-size: 15px;
}

.scroll {
  overflow: hidden;
  flex-wrap: nowrap !important;

}

.list {
  overflow: hidden;
  overflow-y: scroll;
  height: 380px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

.contain {
  object-fit: contain;
  cursor: pointer;
}

.image_avatar {
  max-width: initial;
  max-height: max-content;
  height: 100%;
  width: 100%;
}

.editImage {
  position: absolute;
}

.profile-pic:hover .editImage {
  display: block;
}

.profile-pic {
  position: relative;
  display: inline-block;
}

#fastChannel .actions {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgb(0 15 51 / 73%);
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}

#fastChannel .card-body {
  padding: 0rem;
}

#fastChannel .card-body h4 {
  font-size: 1rem !important;
}

#fastChannel .card-body .actions {
  display: -webkit-flex;
  display: flex;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 2.75rem;
  padding-right: 0.34375rem;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

#fastChannel #button-content {
  padding: 0px;
}

#fastChannel #acortar {
  overflow: hidden;
  text-overflow: ellipsis;
}

#fastChannel #acortarPadre {
  overflow: hidden;
  white-space: nowrap;
}

#fastChannel .acortarParrafo {
  display: block;
  display: -webkit-box;
  line-height: 1.4;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

#fastChannel .wrap {
  white-space: pre-wrap;
}

#fastChannel .body-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

#fastChannel .noBorder {
  border-bottom: 0px;
  width: -webkit-fill-available;
  width: -moz-available;
}

#fastChannel .text-container {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  text-decoration: underline;
  width: 100px;
  /* Ajusta el ancho según tus necesidades */
  display: block;
  /* Asegura que se comporte como un bloque para que text-overflow funcione */
}
</style>
